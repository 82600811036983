<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="deleteCategory($event)" />
    <CategoriesUpdate
      ref="updateCategoires"
      v-model="isModalUpdateCategories"
      :initial-category.sync="initialCategory"
      :selected-item.sync="selectedItem"
      :group-service="groupService"
      @update-category="updateCategory($event)"
    />
    <ModalAddCategories
      ref="addCategoires"
      v-model="isModalAddCategoriesActive"
      :num-lvl="currentlevel"
      :name="name"
      :group-service="groupService"
      @confirm="confirmAddLvl($event)" />
    <StatusModal
      v-model="isConfirmModalActive"
      status-title="Add Category Successfully"
      status-color="#28C76F"
      status-icon="CheckCircleIcon" />
    <div class="container-items">
      <div class="items">
        <!-- level-1 -->
        <div class="item">
          <b-card
            title="Level 1"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(1)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(1)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 1</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group>
                  <b-list-group-item
                    v-for="list, index in lvlOneItems"
                    :key="`list-${index}`"
                    :active="activeList(list, lvlOneSelected)"
                    button
                    @click="selectItem(1, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>

        <!-- levl-2 -->
        <div class="item">
          <b-card
            title="Level 2"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(2)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(2)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 2</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group v-if="lvlOneSelected !== null">
                  <b-list-group-item
                    v-for="list, index in lvlTwoItems"
                    :key="`list-${index}`"
                    :active="activeList(list, lvlTwoSelected)"
                    button
                    @click="selectItem(2, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>

        <!-- levl-3 -->
        <div class="item">
          <b-card
            title="Level 3"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(3)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(3)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 3</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group v-if="lvlTwoSelected">
                  <b-list-group-item
                    v-for="list, index in lvlThreeItems"
                    :key="`list-${index}`"
                    :active="activeList(list, lvlThreeSelected)"
                    button
                    @click="selectItem(3, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>

        <!-- levl-4 -->
        <div class="item">
          <b-card
            title="Level 4"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(4)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(4)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 4</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group v-if="lvlThreeSelected">
                  <b-list-group-item
                    v-for="list, index in lvlFourItems"
                    :key="`list-${index}`"
                    :active="activeList(list, lvlFourSelected)"
                    button
                    @click="selectItem(4, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>

        <!-- levl-5 -->
        <div class="item">
          <b-card
            title="Level 5"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(5)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(5)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 5</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group v-if="lvlFourSelected">
                  <b-list-group-item
                    v-for="list, index in lvlFiveItems"
                    :key="`list-${index}`"
                    :active="activeList(list, lvlFiveSelected)"
                    button
                    @click="selectItem(5, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>

        <!-- levl-6 -->
        <div class="item">
          <b-card
            title="Level 6"
            style="height: 40rem;"
            class="mb-2">
            <b-card-body
              v-if="checkLevel(6)"
              class="p-0">
              <b-button
                variant="Light"
                @click="openAddModal(6)">
                <feather-icon
                  icon="PlusIcon" />
                <span class="align-middle">Add Level 6</span>
              </b-button>
              <b-card-body
                style="overflow: scroll;
                height: 32rem;
                padding: 0;">
                <b-list-group v-if="lvlFiveSelected">
                  <b-list-group-item
                    v-for="list, index in lvlSixItems"
                    :key="`list-${index}`"
                    button
                    :active="activeList(list, lvlSixSelected)"
                    @click="selectItem(6, list)">
                    <div style="text-align: right;">
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openUpdateModal(list)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(200, 0, 0,)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        @click="openDeleteModal(list)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ list.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteModal from '@/components/DeleteModal.vue'
import ModalAddCategories from '@/components/Modal/ModalAddCategories.vue'
import CategoriesUpdate from '@/components/Categories/CategoriesUpdate.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GroupProvider from '@/resources/GroupProvider'

const CategoriesService = new CategoriesProvider()
const GroupService = new GroupProvider()

export default {
  components: {
    DeleteModal,
    ModalAddCategories,
    CategoriesUpdate,
    StatusModal
  },
  props: {
    name: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      categories: [],
      deleteItem: {},
      groupService: [],
      isModalAddCategoriesActive: false,
      isModalUpdateCategories: false,
      isConfirmModalActive: false,
      isDeleteModalActive: false,
      lvlOneItems: [],
      lvlTwoItems: [],
      lvlThreeItems: [],
      lvlFourItems: [],
      lvlFiveItems: [],
      lvlSixItems: [],
      lvlOneSelected: null,
      lvlTwoSelected: null,
      lvlThreeSelected: null,
      lvlFourSelected: null,
      lvlFiveSelected: null,
      lvlSixSelected: null,
      currentlevel: 0,
      currentParentId: 0,
      objCurrentParentId: {
        lvlOne: 0,
        lvlTwo: 0,
        lvlThree: 0,
        lvlFour: 0,
        lvlFive: 0,
        lvlSix: 0
      },
      selectedItem: {},
      initialCategory: {}
    }
  },
  mounted () {
    this.getAllCategory()
    this.getGroupService()
  },
  methods: {
    async getAllCategory () {
      try {
        const { data } = await CategoriesService.findAll()
        this.categories = data
        const level1 = data.filter((item) => item.level === 1)
        this.lvlOneItems = level1
        // data.forEach((item) => {
        //   switch (item.level) {
        //   case 1:
        //     this.lvlOneItems.push(item)
        //     break
        //   case 2:
        //     this.lvlTwoItems.push(item)
        //     break
        //   case 3:
        //     this.lvlThreeItems.push(item)
        //     break
        //   case 4:
        //     this.lvlFourItems.push(item)
        //     break
        //   case 5:
        //     this.lvlFiveItems.push(item)
        //     break
        //   case 6:
        //     this.lvlSixItems.push(item)
        //     break
        //   default:
        //     break
        //   }
        // })
      } catch (error) {
        console.error(error)
      }
    },
    async addCategory (data) {
      try {
        const payload = { ...data }
        switch (payload.level) {
        case 2:
          payload.parentId = this.objCurrentParentId.lvlOne
          break
        case 3:
          payload.parentId = this.objCurrentParentId.lvlTwo
          break
        case 4:
          payload.parentId = this.objCurrentParentId.lvlThree
          break
        case 5:
          payload.parentId = this.objCurrentParentId.lvlFour
          break
        case 6:
          payload.parentId = this.objCurrentParentId.lvlFive
          break
        default:
          payload.parentId = 0
        }
        await CategoriesService.createGroup(payload)
        await this.getAllCategory()
        this.$refs.addCategoires.resetForm()
        this.isModalAddCategoriesActive = !this.isModalAddCategoriesActive
        this.setDataIntitalCategories(payload.level, payload.parentId)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Categories  has been add'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Categories add has been fail '
          }
        })
      }
    },
    async updateCategory (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await CategoriesService.updateGroup(val.id, payload)
        await this.getAllCategory()
        this.setDataIntitalCategories(data.level, data.parentId)
        this.setInitialCategoryAndSelectItem(data)
        this.isModalUpdateCategories = !this.isModalUpdateCategories
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Categories  has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Categories has been Failed'
          }
        })
      }
    },
    async deleteCategory (val) {
      try {
        const { data } = await CategoriesService.deleteGroup(val.ID)
        await this.getAllCategory()
        this.setDataIntitalCategories(data.level, data.parentId)
        this.clearCategory(data.level - 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Categories has been deleted'
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getGroupService () {
      try {
        const { data } = await GroupService.findAll(1, 9999)
        if (Array.isArray(data)) {
          this.groupService = data.filter((item) => item?.status === 'active')
        }
      } catch (error) {
        console.error(error)
      }
    },
    openUpdateModal (event) {
      this.setInitialCategoryAndSelectItem(event)
      this.isModalUpdateCategories = !this.isModalUpdateCategories
    },
    openDeleteModal (data) {
      this.deleteItem = { ID: data.id, Name: data.name }
      this.isDeleteModalActive = !this.isDeleteModalActive
    },
    filterCategory (level, parentId) {
      return this.categories.filter((item) => item.level <= level + 1 && item.parentId === parentId)
    },
    confirmAddLvl (value) {
      this.addCategory(value)
      // switch (value.level) {
      // case 1:
      //   this.lvlOneItems.push(value)
      //   break
      // case 2:
      //   this.lvlTwoItems.push(value)
      //   break
      // case 3:
      //   this.lvlThreeItems.push(value)
      //   break
      // case 4:
      //   this.lvlFourItems.push(value)
      //   break
      // case 5:
      //   this.lvlFiveItems.push(value)
      //   break
      // case 6:
      //   this.lvlSixItems.push(value)
      //   break
      // default:
      //   return null
      // }
      return null
    },
    activeList (item, selected) {
      return JSON.stringify(selected) === JSON.stringify(item)
    },
    openAddModal (currentlevel) {
      this.isModalAddCategoriesActive = true
      this.currentlevel = currentlevel
    },
    selectItem (level, value) {
      if (value && value.id) {
        this.currentParentId = value.id
      }
      if (level) {
        this.currentlevel = level
      }
      const data = this.filterCategory(level, value.id)
      if (level === 1) {
        this.objCurrentParentId.lvlOne = value.id
        this.lvlOneSelected = value
        this.lvlTwoItems = data
      } else if (level === 2) {
        this.objCurrentParentId.lvlTwo = value.id
        this.lvlTwoSelected = value
        this.lvlThreeItems = data
      } else if (level === 3) {
        this.objCurrentParentId.lvlThree = value.id
        this.lvlThreeSelected = value
        this.lvlFourItems = data
      } else if (level === 4) {
        this.objCurrentParentId.lvlFour = value.id
        this.lvlFourSelected = value
        this.lvlFiveItems = data
      } else if (level === 5) {
        this.objCurrentParentId.lvlFive = value.id
        this.lvlFiveSelected = value
        this.lvlSixItems = data
      } else if (level === 6) {
        this.objCurrentParentId.lvlSix = value.id
        this.lvlSixSelected = value
      }
      this.clearCategory(level)
    },
    clearCategory (currentLevel) {
      switch (currentLevel) {
      case 0:
        this.lvlTwoItems = []
        this.lvlThreeItems = []
        this.lvlFourItems = []
        this.lvlFiveItems = []
        this.lvlSixItems = []
        this.lvlTwoSelected = null
        this.lvlThreeSelected = null
        this.lvlFourSelected = null
        this.lvlFiveSelected = null
        this.lvlSixSelected = null
        break
      case 1:
        this.lvlThreeItems = []
        this.lvlFourItems = []
        this.lvlFiveItems = []
        this.lvlSixItems = []
        this.lvlTwoSelected = null
        this.lvlThreeSelected = null
        this.lvlFourSelected = null
        this.lvlFiveSelected = null
        this.lvlSixSelected = null
        break
      case 2:
        this.lvlFourItems = []
        this.lvlFiveItems = []
        this.lvlSixItems = []
        this.lvlThreeSelected = null
        this.lvlFourSelected = null
        this.lvlFiveSelected = null
        this.lvlSixSelected = null
        break
      case 3:
        this.lvlFiveItems = []
        this.lvlSixItems = []
        this.lvlFourSelected = null
        this.lvlFiveSelected = null
        this.lvlSixSelected = null
        break
      case 4:
        this.lvlFiveSelected = null
        this.lvlSixItems = []
        this.lvlSixSelected = null
        break
      case 5:
        this.lvlSixSelected = null
        break
      default:
        break
      }
    },
    checkLevel (level) {
      switch (level) {
      case 1: return true
      case 2: return this.lvlOneSelected
      case 3: return this.lvlTwoSelected
      case 4: return this.lvlThreeSelected
      case 5: return this.lvlFourSelected
      case 6: return this.lvlFiveSelected
      default: return false
      }
    },
    setInitialCategoryAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialCategory = { ...val }
    },
    setDataIntitalCategories (level, parentId) {
      const filter = this.filterCategory(level, parentId)
      switch (level) {
      case 1:
        this.lvlOneItems = filter
        break
      case 2:
        this.lvlTwoItems = filter
        break
      case 3:
        this.lvlThreeItems = filter
        break
      case 4:
        this.lvlFourItems = filter
        break
      case 5:
        this.lvlFiveItems = filter
        break
      case 6:
        this.lvlSixItems = filter
        break
      default:
        break
      }
    }
  }
}

</script>

<style scoped>
.container-items {
  overflow: scroll;
  width: 100%;
}
.items {
  display: flex;
  flex-wrap: nowrap;
}
.items .item {
  min-width: 350px;
  text-align: center;
  padding: 5px;
}
.levelItem {
  font-weight: 900;
  font-size: large;
  text-align: start;
}
</style>
